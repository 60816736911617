import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';

import AnchorLink from 'react-anchor-link-smooth-scroll';

const Index = () => (
  <Layout>
    <Helmet>
      <title>Flexa &#8211; Fast Acting Advanced Natural Pain Relief </title>
    </Helmet>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Here is Relief
          </h1>

          <p className="mt-3 mt-12 mb-10">
            <a
              href="https://www.amazon.com/FLEXA%C2%AE-Penetrating-Arthritis-Fibromyalgia-Neuropathy/dp/B01KUAKFSG"
              className="hero-button mr-5"
              target="_blank"
            >
              Buy Now
            </a>
            <AnchorLink className="hero-button" href="#info">
              Learn More
            </AnchorLink>
          </p>
        </div>
        <div className="lg:w-1/2 mt-10">
          <img src={`../../flexa-sm.png`} alt="Flexa" />
        </div>
      </div>
    </section>
    <section id="info" className="py-20 lg:pb-40 lg:pt-25">
      <div className="container mx-auto py-4 px-8">
        <div className="flex flex-col sm:flex-row sm:-mx-2 mt-12">
          <div className="flex-1 py-5  px-3 md:pr-20">
            <h3 className="text-3xl font-semibold leading-tight mb-10 ">
              Fast Acting Advanced Natural Pain Relief
            </h3>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              FLEXA® Cream was developed in response to the demand for safe, natural and
              non-addictive pain relief. Many People are concerned about the growing rate of
              addiction to prescription opiates along with the systemic side effects and overdoses
              of everything from acetaminophen to ibruprofen.
            </p>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              FLEXA® utilizes optimal doses of menthol and natural ingredients to provide pain
              relief for a variety of pain sufferers. It uses a topical delivery system to bring
              these soothing ingredients directly to the painful areas of the body, without using an
              oral tablet, capsule or pill that has to travel through the body’s entire blood stream
              just to get to the symptomatic area.
            </p>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              This direct topical treatment approach targets relief at the site of the pain while
              minimizing effects elsewhere in the body by reducing the risk of systemic side effects
              on the GI tract, liver, and kidneys seen with oral medications.
            </p>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              While no single medicine works for every person or pain condition, we have had
              overwhelmingly positive feedback from our FLEXA® Cream customers.
            </p>
          </div>
          <div className="flex-1 px-5 py-5 pl-10">
            <h3 className="text-3xl font-semibold leading-tight mb-10">
              Common Sources of Joint and Muscle Pain
              <img src={`../../source.png`} alt="Pain Source" className="py-10" />
            </h3>
          </div>
        </div>
      </div>
    </section>
    <section id="ingredients" className="py-10 lg:pb-40 lg:pt-25">
      <div className="container mx-auto py-1 px-8">
        <div className="flex flex-col sm:flex-row sm:-mx-2 ">
          <div className="flex-1 px-3 ">
            <h3 className="text-3xl font-semibold leading-tight pr-10">Ingredients</h3>
            <p className="mt-5 text-lg font-light leading-relaxed py-10 text-justify">
              This terrific product does an excellent job of delivering relieff in a safe topical
              formulation that feels great as it massages into the skin while delivering a potent
              blend of Arnica, MSM and Ilex Paraguariensis to address inflamation. These natural
              ingredients are intended to provide additional soothing benefits complementing the
              fast acting effects of the Menthol for combination relief.
            </p>
            <div className="mx-auto">
              <img src={`../../ingredients.png`} alt="Ingredients" className="mx-auto py-10" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="direction" className="container mx-auto  py-20 bg-gray-200 rounded-lg ">
      <SplitSection
        primarySlot={
          <div className="lg:pr-17 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">Direction for Use</h3>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              Apply and massage onto affected areas for 30 seconds until thoroughly absorbed through
              the skin. The appropriate amount of FLEXA® Cream to use should be enough so that the
              cream massages in smoothly & comfortable over the entire treatment area. Repeat as
              needed, no more than 4 times daily.
            </p>
            <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-justify">
              Wash hands after use with cool water. See product label for complete warnings and
              precautions and instructions before use. Start by following the application regiment
              above 3 to 4 times a day for 7 days. Use as needed as symptoms are reduced. By this
              point you should be experiencing the true benefit and therapeutic effect of this pain
              reliever.
            </p>
          </div>
        }
        secondarySlot={
          <img src={`../../ManStanding-sm.png`} alt="Man Standing" className="mx-auto py-10" />
        }
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-17 xl:pl-48">
            <p className="mt-8 text-lg font-light leading-relaxed text-justify">
              <strong>Helpful Tips:</strong> If you typically experience pain symptoms at night, you
              may find it useful to massage FLEXA® Cream into the symptomatic area just before going
              to sleep for the night. If you experience pain symptoms before certain activities,
              like exercise, sports or typing on a keyboard, you should massage FLEXA® Cream onto
              the appropriate areas shortly before these activities and again after them for
              soothing recovery.
            </p>
            <p className="mt-8 text-lg font-light leading-relaxed text-justify">
              As your symptoms improve, you may require less frequent applications.
            </p>
          </div>
        }
        secondarySlot={
          <img src={`../../ManRunning-sm.png`} alt="Man Running" className="mx-auto py-10" />
        }
      />
    </section>
    <section id="contact" className="container mx-auto  rounded-lg mt-40 text-center ">
      <h3 className="text-3xl font-semibold leading-tight">Contact Us</h3>
      <p className="mt-8 text-lg font-light leading-relaxed text-gray-500 text-center">
        We’d love to hear from you <br />
        <a href="mailto:info@flexalabs.com">info@flexalabs.com </a>
      </p>
    </section>
  </Layout>
);

export default Index;
